.__react_component_tooltip {
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    left: -999em;
    opacity: 0;
    padding: 8px 21px;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    top: -999em;
    visibility: hidden;
    z-index: 999;
}
.__react_component_tooltip.allow_hover, .__react_component_tooltip.allow_click {
    pointer-events: auto;
}
.__react_component_tooltip::before, .__react_component_tooltip::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
}
.__react_component_tooltip.show {
    opacity: 0.9;
    margin-top: 0;
    margin-left: -30px!important;
    visibility: visible;
}
/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px){
    .__react_component_tooltip.show {
        opacity: 0.9;
        margin-top: 0;
        margin-left: 0px!important;
        visibility: visible;
    }
}
/* Tablets y phablets */

@media only screen and (min-width: 768px) and (max-width: 800px){
    .__react_component_tooltip.show {
        opacity: 0.9;
        margin-top: 0;
        margin-left: 0px!important;
        visibility: visible;
    }
}
/*phone*/
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .__react_component_tooltip.show {
        opacity: 0.9;
        margin-top: 0;
        margin-left: 0px!important;
        visibility: visible;
    }
}






.__react_component_tooltip.place-top::before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -8px;
    left: 50%;
    margin-left: -10px;
}
.__react_component_tooltip.place-bottom::before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -8px;
    left: 50%;
    margin-left: -10px;
}
.__react_component_tooltip.place-left::before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    right: -8px;
    top: 50%;
    margin-top: -5px;
}
.__react_component_tooltip.place-right::before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    left: -8px;
    top: 50%;
    margin-top: -5px;
}
.__react_component_tooltip .multi-line {
    display: block;
    padding: 2px 0;
    text-align: center;
}



