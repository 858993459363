.Swiper {
    width: 100%;
    /*   height: 100%;*/
}

.Swiper-slide {
    /*text-align: center;*/
    /* font-size: 18px;*/
    /*  background: red;*/

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper {
    --swiper-navigation-color: #004876 !important;
    --swiper-pagination-color: #004876 !important;
}
/*
.Swiper{
    --add-bottom: 50px;
    padding-bottom: var(--add-bottom);
}
.Swiper [class^="swiper-button-"]{
    top: calc(50% - var(--add-bottom) / 2);
}*/

